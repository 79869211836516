const roomPricePlatform = {
  "TYPES" : [
    {
      "Value" : 'ALL',
      "Title" : 'TÜM KANLALLAR',
    },
    {
      "Value" : 'B2B',
      "Title" : 'B2B KANALLAR',
    },
    {
      "Value" : 'B2C',
      "Title" : 'B2C KANALLAR',
    }],
};
export default roomPricePlatform;
