const roomPriceType = {
  "TYPES" : [
    {
      "Value" : 'PAX',
      "Title" : 'KİŞİ FİYATI',
    },
    {
      "Value" : 'UNITE',
      "Title" : 'ODA FİYATI',
    }],
  "PAX" : [
    {
      "Value" : '1',
      "Title" : 'SINGLE',
    },
    {
      "Value" : '2',
      "Title" : 'DPP',
    },
    {
      "Value" : '3',
      "Title" : '3.KİŞİ FARKI',
    },
    {
      "Value" : '4',
      "Title" : '4.KİŞİ FARKI',
    },
    {
      "Value" : '5',
      "Title" : '5.KİŞİ FARKI',
    }],
  "UNITE" : [
    {
      "Value" : '1',
      "Title" : 'ODA FİYATI',
    }],
};
export default roomPriceType;
