const roomCalcPriceType = {
  "TYPES" : [
    {
      "Value" : 'COM',
      "Title" : 'KOMİSYONLU',
    },
    {
      "Value" : 'NET',
      "Title" : 'NET',
    }],
};
export default roomCalcPriceType;
