const curExchangeType = {
  "TYPES" : [
    {
      "Value" : 'DYNAMIC',
      "Title" : 'DİNAMİK KUR',
    },
    {
      "Value" : 'CUSTOME',
      "Title" : 'ÖZEL KUR',
    }],
};
export default curExchangeType;
