const roomMinAccType = {
  "TYPES" : [
    {
      "Value" : 'PERIOD',
      "Title" : 'PERİOT',
    },
    {
      "Value" : 'BOOKING',
      "Title" : 'REZERVASYON',
    }],
  "CONDITION" : [
    {
      "Value" : 'EQL',
      "Title" : 'EŞİT (=)',
    },
    {
      "Value" : 'GTE',
      "Title" : 'BÜYÜK EŞİT (>=)',
    }],
  "DAYS" : [
    {
      "Value" : '1',
      "Title" : '1 GECE',
    },
    {
      "Value" : '2',
      "Title" : '2 GECE',
    },
    {
      "Value" : '3',
      "Title" : '3 GECE',
    },
    {
      "Value" : '4',
      "Title" : '4 GECE',
    },
    {
      "Value" : '5',
      "Title" : '5 GECE',
    },
    {
      "Value" : '6',
      "Title" : '6 GECE',
    },
    {
      "Value" : '7',
      "Title" : '7 GECE',
    },
    {
      "Value" : '8',
      "Title" : '8 GECE',
    },
    {
      "Value" : '9',
      "Title" : '9 GECE',
    },
    {
      "Value" : '10',
      "Title" : '10 GECE',
    },
    {
      "Value" : '11',
      "Title" : '11 GECE',
    },
    {
      "Value" : '12',
      "Title" : '12 GECE',
    },
    {
      "Value" : '13',
      "Title" : '13 GECE',
    },
    {
      "Value" : '14',
      "Title" : '14 GECE',
    }],
};
export default roomMinAccType;
