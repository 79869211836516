
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import RoomPriceAdd2 from "@/components/hotel/RoomPricesAdd2.vue";
import RoomPriceUpdate from "@/components/hotel/RoomPricesUpdate.vue";
import {dateConvert} from "@/core/filters/datatime";
import {commonf} from "@/core/filters/common";

import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import weekDaysList from "@/core/data/weekdays";

export default defineComponent({
    name: "overview",
    computed: {
        libTools() {
            return libTools
        }
    },
    components: {
        RoomPriceAdd2,
        RoomPriceUpdate,
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Otel Aksiyon", ["Otel"]);
        });

        const route = useRoute();
        const hotelID = route.params.id;
        const payload = {
            ID: hotelID,
        }
        //console.log(payload);
        store.dispatch(Actions.HOTEL_DETAILS, payload);
        const myDetails = computed(() => {
            return store.getters.hotelDetails;
        });

        const payload3 = {
            HotelID: hotelID,
        }

        const arrRoomPriceGroup = ref([]);
        const arrRoomPriceList = ref([]);


        function deleteGroup(prmGroupID) {
          const theRoomPriceGroup = getRoomPriceGroupByGroupID(prmGroupID);
          //const  arrItems = getRoomPricesByPriceInfo(theRoomPriceGroup.ConceptTitle, theRoomPriceGroup.CheckInDate, theRoomPriceGroup.CheckOutDate, theRoomPriceGroup.ValidDay);
          const arrPriceIDs = [];

          for(let i=0; i<theRoomPriceGroup.arrItems.length; i++){
            const each = theRoomPriceGroup.arrItems[i];
            arrPriceIDs.push(each.ID);
          }

          console.log( theRoomPriceGroup.arrItems);
          console.log( arrPriceIDs);
          //console.log(arrItems );

          Swal.fire({title: 'Emin misiniz?', text: "Kaydı silmek işlemi geri alınamaz.", icon: "warning", showCancelButton: true,
            confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`, confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
          }).then(function (result) {
            if (result.isConfirmed) {
              store.dispatch(Actions.HOTEL_ROOM_PRICE_DELETE_BULK, {  IDs: arrPriceIDs})
                  .then(() => {
                    location.reload();
                    store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST, payload3);
                    Swal.fire( 'Silindi!', 'Kayit silme işlemi tamamlandı.', 'success')
                  }).catch(() => {
                Swal.fire({ text: store.getters.getHotelActionsErrors[0], icon: "error", buttonsStyling: false,
                  confirmButtonText: "Tekrar dene!", customClass: {confirmButton: "btn fw-bold btn-light-danger", },
                });
              });
            }
          });


        }


        function getRoomPricesByPriceInfo (prmConceptTitle, prmCheckInDate, prmCheckOutDate, prmValidDay){
          const confirmResults = arrRoomPriceList.value.filter(obj =>{
            return obj.ConceptTitle === prmConceptTitle + "" && obj.CheckInDate === prmCheckInDate + "" && obj.CheckOutDate === prmCheckOutDate + "" && obj.ValidDay === prmValidDay;
          })
          return confirmResults;
        }

        function getRoomPriceGroupByGroupID (prmGroupID){
          const confirmResults = arrRoomPriceGroup.value.find(obj =>{
            return obj.GroupID + "" === prmGroupID + "" ;
          })
          return confirmResults;
        }

        function getRoomPriceByGroup(prmConceptTitle, prmCheckInDate, prmCheckOutDate, prmValidDay ) {
          for (let i=0; i<arrRoomPriceGroup.value.length; i++){
            let each = arrRoomPriceGroup.value[i];
            if(each.ConceptTitle === prmConceptTitle && each.CheckInDate === prmCheckInDate && each.CheckOutDate === prmCheckOutDate && each.ValidDay === prmValidDay){

              return each;
            }
          }

          return null;
        }

        function fillPriceList (prmData) {
          if (prmData !== undefined){
            for (let i=0; i<prmData.length; i++){
              let each = prmData[i];
              let eachPriceGroup = getRoomPriceByGroup(each.ConceptTitle, each.CheckInDate, each.CheckOutDate, each.ValidDay);

              if (eachPriceGroup==null){

                arrRoomPriceGroup.value.push({
                  GroupID: arrRoomPriceGroup.value.length,
                  ConceptTitle: each.ConceptTitle,
                  CheckInDate: each.CheckInDate,
                  CheckOutDate: each.CheckOutDate,
                  ValidDay: each.ValidDay,
                  arrItems : getRoomPricesByPriceInfo(each.ConceptTitle, each.CheckInDate, each.CheckOutDate, each.ValidDay),
                })
              }
            }
          }
        }

        store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST,  payload3).then((prmData) => {
          arrRoomPriceList.value=prmData.data;
          fillPriceList(prmData.data);
        })

        const updateActive = (ID, Active) => {
            const payload = {
                ID: ID,
                HotelID: hotelID,
                is_active: (Active == 'active') ? "passive" : "active",
            }
            store.dispatch(Actions.HOTEL_ROOM_PRICE_EDIT, payload)
        }

        const deleteItem = (ID) => {
            Swal.fire({title: 'Emin misiniz?', text: "Kaydi silmek işlemi geri alınamaz.", icon: "warning", showCancelButton: true,
              confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`, confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    store.dispatch(Actions.HOTEL_ROOM_PRICE_DELETE, {  ID: ID, HotelID: hotelID,})
                        .then(() => {
                          location.reload();
                          store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST, payload3);
                            Swal.fire( 'Silindi!', 'Kayit silme işlemi tamamlandı.', 'success')
                        }).catch(() => {
                        Swal.fire({ text: store.getters.getHotelActionsErrors[0], icon: "error", buttonsStyling: false,
                          confirmButtonText: "Tekrar dene!", customClass: {confirmButton: "btn fw-bold btn-light-danger", },
                        });
                    });
                }
            });
        }

        let itemID = ref<number>(0);

        function setItems(ID) {
            itemID.value = ID;
        }

        let dummyID = ref<number>(0);
        function setDummy() {
            dummyID.value = Math.floor(Date.now() / 1000);
        }

        return {
            hotelID,
            myDetails,
            dateConvert,
            itemID,
            dummyID,
            setItems,
            setDummy,
            updateActive,
            deleteItem,
            commonf,
            weekDaysList,
            arrRoomPriceGroup,
            getRoomPricesByPriceInfo,
            arrRoomPriceList,
            deleteGroup,
        };
    },
});
