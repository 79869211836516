const weekDaysList = {
  "DAYS" : [
    {
      "Value" : '1',
      "Title" : 'Pazartesi',
      "Shorthand" : 'Pzt',
    },
    {
      "Value" : '2',
      "Title" : 'Salı',
      "Shorthand" : 'Sal',
    },
    {
      "Value" : '3',
      "Title" : 'Çarşamba',
      "Shorthand" : 'Çar',
    },
    {
      "Value" : '4',
      "Title" : 'Perşembe',
      "Shorthand" : 'Per',
    },
    {
      "Value" : '5',
      "Title" : 'Cuma',
      "Shorthand" : 'Cum',
    },
    {
      "Value" : '6',
      "Title" : 'Cumartesi',
      "Shorthand" : 'Cmt',
    },
    {
      "Value" : '7',
      "Title" : 'Pazar',
      "Shorthand" : 'Pzr',
    }],
};
export default weekDaysList;
